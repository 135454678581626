import React from 'react'
import HireRightIntern from '../components/recruitment_blogs/blogs/hire_the_right_intern/hire_right_intern'

import SEO from '../components/seo'

const Page = (location) => {
  return (<React.Fragment>
      <SEO title="10 Tips to Hire the Right Interns"
        description=" A comprehensive guide on finding the right interns every time. 10 tips to build a successful internship program. "
        url="/hire-the-right-intern/"
        image="https://blogs.mycareerdreams.com/blogs/intern_w.webp"
        type="article"
      />
    <HireRightIntern location={location}/>
  </React.Fragment>
  )
}
export default Page;